import { constantsTypes } from "../reduxConstants";

const initialValue = { lng: [], grs: [], accsblity: false, status: 0 };

export const mobileFilterReduce = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.MOBILE_FILTER:
      return action.value;
    default:
      return state;
  }
};
