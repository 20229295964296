import { constantsTypes } from "../reduxConstants";

const initialValue = { cutPrice: 0, finalPrice: 0 };

export const grandTotalData = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.GRAND_TOTAL:
      return action.value
    default:
      return state
  }
};