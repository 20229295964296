import { constantsTypes } from '../reduxConstants'

const initialValue = {value: false};

export const loginViewMode = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.LOGIN_VIEW:
      return action.value
    default:
      return state
  }
}