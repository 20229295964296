const isMobile = window.matchMedia("(max-width: 575px)").matches;
const theme = localStorage.getItem("theme");

export const env = "prod"
export const cinemaChain = "INOX";
export const base_url = "https://api3.inoxmovies.com/api/"; //PREPROD
export const chain = theme === "inoxTheme" ? "INOX" : "PVR";
export const av = "1.0";
export const pt = isMobile ? "MSITE" : "WEBSITE";
export const did = "48530476451693207526480";
export const userid = "0";
export const dtmsource = "null";
export const lang = "ALL";
export const clientId =
  "658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com";
export const appId = "1088597931155576";
export const cname = "INDIA";
export const ISaccount = "pvrltd"
export const ISdataset = "pvrweb_prod"
export const apiHeader = {
  Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  chain: theme === "inoxTheme" ? "INOX" : "PVR",
  city: localStorage.getItem("city"),
  appVersion: "1.0",
  platform: isMobile ? "MSITE" : "WEBSITE",
  country: "INDIA",
};
export const REACT_APP_GG_APP_ID ="178583874387-2acar1rjub7ap6l00llcu6k3jm57nmlp.apps.googleusercontent.com";
export const REACT_APP_FB_APP_ID="68386086163"
