
export const pvrTheme = {
    colors:{
        bgColor:"#FFCB05",
        bgColorLight:"#FFF0D6",
        txtColor:"#000"
    }
}

export const inoxTheme = {
    colors:{
        bgColor:"#234B9E",
        bgColorLight:"#DCE2EF",
        txtColor:"#fff"
    }
}