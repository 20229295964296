import { constantsTypes } from "../reduxConstants";

const initialValue = { city : '', change: false };

export const cityChangeData = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.CITY_CHANGE:
      return action.value
    default:
      return state
  }
};