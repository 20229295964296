import { constantsTypes } from "../reduxConstants";

const initialValue = {};

export const comingSoonMovieReduce = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.NOW_SHOWING_MOVIE:
      return action.value;
    default:
      return state;
  }
};