import { constantsTypes } from '../reduxConstants'

const initialValue = {value: false};

export const movieDetailsViewMode = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.MOVIE_DETAILS_VIEW:
      return action.value
    default:
      return state
  }
}