import { constantsTypes } from "../reduxConstants";

const initialValue = { value: 0 };

export const trailerViewMode = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.TRAILER_MODE:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
