import { constantsTypes } from "../reduxConstants";

const initialData = [];

export const cartReduceHandler = (state = initialData, action) => {
  switch (action.type) {
    case constantsTypes.ADD_TO_CART:
      return action.payload;
      break;
    case constantsTypes.REMOVE_FROM_CART:
      return action.payload;

    default:
      return state;
      break;
  }
};